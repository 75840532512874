import type { RecommendationStrategy } from "@/next-js-app/components/PersonalizedProductLists/Handler/Handler.helper";
import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";
import { productCarouselColorTheme } from "./personalized-product-list-helper";

type PersonalizedWrapperProps = {
  backgroundColor?: string;
  carouselType: RecommendationStrategy;
  fontColor?: string;
  isNextApp?: boolean;
};

export const SeeAllProductsButton = styled.a`
  margin-top: ${spaces.large};
  width: 100%;

  ${MQ("smallTablet")} {
    width: fit-content;
    margin: ${spaces.large} auto 0;
  }
`;

export const Link = styled.a<PersonalizedWrapperProps>(
  ({ backgroundColor, carouselType, fontColor }) => css`
    display: flex;
    align-items: center;
    min-width: 100px;
    justify-content: flex-end;
    ${productCarouselColorTheme({ backgroundColor, carouselType, fontColor })};
    background-color: transparent;

    ${MQ("tablet")} {
      width: fit-content;
    }
  `
);

export const LinkDisplayName = styled.span`
  margin-right: ${spaces.micro};
  text-align: right;
  ${typographyToCss({
    fontFamily: "var(--font-family-bold)",
    fontSize: 15,
  })}
`;

export const PersonalizedWrapper = styled.div<
  PersonalizedWrapperProps & { hasTopMargin: boolean }
>(
  ({
    backgroundColor,
    carouselType,
    fontColor,
    hasTopMargin,
    isNextApp = false,
  }) => css`
    margin-top: ${hasTopMargin ? spaces.large : 0};
    //padding is related to the global class - container
    padding: ${spaces.smallRegular} ${spaces.smallRegular};
    ${productCarouselColorTheme({ backgroundColor, carouselType, fontColor })}

    ${MQ("tabletHorizontal")} {
      ${isNextApp &&
      css`
        padding: 54px ${spaces.huge};
      `}
    }

    ${MQ("laptop")} {
      margin-top: ${hasTopMargin ? spaces.almostHuge : 0};
      padding: ${spaces.almostHuge} ${spaces.mediumHuge};

      ${isNextApp &&
      css`
        padding: 54px ${spaces.huge};
      `}
    }

    ${MQ("bigDesktop")} {
      padding: 54px calc((100% - 1332px) / 2);
    }
  `
);
